import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
// import { Sortable } from "react-sortable";
// import { ReactSortable } from "react-sortablejs";
import Sortable from "sortablejs";

import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  Paper,
} from "@mui/material";
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import Checkbox from '@mui/material/Checkbox';
// import Button from '@mui/material/Button';
// import Paper from '@mui/material/Paper';

class Transfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      left: [],
      right: [],
      checked: [],
      leftChecked: [],
    };
  }

  // intersection = (a, b) => {
  //   return a.filter((value) => b.indexOf(value) !== -1);
  // }

  componentDidMount() {
    const ctx = this;
    if (this.props.frames !== null) {
      this.setState({ left: this.props.frames });
    }
    if (this.props.selectedFrame.length !== 0) {
      this.setState({ right: this.props.selectedFrame });
    }
    var el = document.getElementById("items");
    var sortable = Sortable.create(el, {
      onUpdate: async function (event) {
        const oldIndex = event.oldIndex;
        const newIndex = event.newIndex;
        await ctx.sortRight(oldIndex, newIndex);
      },
    });
  }

  sortRight = (oldIndex, newIndex) => {
    let newArr = [...this.state.right];
    const element = newArr.splice(oldIndex, 1);
    // Use splice to insert the element at the new index
    newArr.splice(newIndex, 0, element[0]);

    // // Use the spread operator to create a copy of the array
    // let newArr = [...this.state.right];

    // // Use array destructuring assignment to swap the elements
    // [newArr[oldIndex], newArr[newIndex]] = [newArr[newIndex], newArr[oldIndex]];

    this.setState({ right: newArr }, function() {
      this.props.handleSelectedFrame(this.state.right);
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.frames !== this.props.frames) {
      this.setState({ left: this.props.frames });
    }
    if (prevProps.selectedFrame !== this.props.selectedFrame) {
      this.setState({ right: this.props.selectedFrame });
    }
  }

  handleToggle = (value, event) => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked });
  };

  handleAllRight = () => {
    this.setState(
      {
        right: this.state.right.concat(this.state.left),
        left: [],
      },
      function () {
        this.props.handleSelectedFrame(this.state.right);
      }
    );
  };

  handleCheckedRight = () => {
    const newRight = [...this.state.right];
    const newLeft = [...this.state.left];
    this.state.checked.forEach((el, i) => {
      const indexRight = newRight.indexOf(el);
      const indexLeft = newLeft.indexOf(el);
      if (indexRight === -1) {
        newRight.push(el);
      }

      if (indexLeft !== -1) {
        newLeft.splice(indexLeft, 1);
      }
    });
    this.setState({ right: newRight, checked: [], left: newLeft }, function() {
      this.props.handleSelectedFrame(this.state.right);
    });
  };

  handleCheckedLeft = () => {
    const newRight = [...this.state.right];
    const newLeft = [...this.state.left];
    this.state.checked.forEach((el, i) => {
      const indexRight = newRight.indexOf(el);
      const indexLeft = newLeft.indexOf(el);
      if (indexLeft === -1) {
        newLeft.push(el);
      }

      if (indexRight !== -1) {
        newRight.splice(indexRight, 1);
      }
    });
    this.setState({ right: newRight, checked: [], left: newLeft }, function() {
      this.props.handleSelectedFrame(this.state.right);
    });
  };

  handleAllLeft = () => {
    this.setState({
      left: this.state.left.concat(this.state.right),
      right: [],
    }, function() {
      this.props.handleSelectedFrame(this.state.right);
    });
  };

  render() {
    const customList = (items) => (
      <Paper sx={{ height: 400, overflow: "auto" }}>
        <List dense component="div" role="list">
          {items.map((value, key) => {
            const labelId = `transfer-list-item-${value.id}-label`;

            return (
              <ListItem
                key={key}
                role="listitem"
                button
                onClick={this.handleToggle.bind(this, value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={this.state.checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <img
                  alt="fullimage-thumbnail"
                  width="50px"
                  height="auto"
                  src={value.fullurl_image}
                  style={{ marginRight: "10px" }}
                />
                <ListItemText id={labelId} primary={`${value.name}`} />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    );

    const customSortList = (items) => (
      <Paper sx={{ height: 400, overflow: "auto" }}>
        <List dense component="div" role="list" id="items">
          {items.map((value, key) => {
            return (
              <ListItem
                key={value.id}
                role="listitem"
                button
                onClick={this.handleToggle.bind(this, value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={this.state.checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": `transfer-list-item-${value.id}-label`,
                    }}
                  />
                </ListItemIcon>
                <img
                  alt="fullimage-thumbnail"
                  width="50px"
                  height="auto"
                  src={value.fullurl_image}
                  style={{ marginRight: "10px" }}
                />
                <ListItemText
                  id={`transfer-list-item-${value.id}-label`}
                  primary={`${value.name}`}
                />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    );

    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={5} lg={5}>
          {customList(this.state.left)}
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={this.handleAllRight}
              disabled={this.state.left.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={this.handleCheckedRight}
              // disabled={this.state.leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={this.handleCheckedLeft}
              // disabled={this.state.rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={this.handleAllLeft}
              disabled={this.state.right.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} lg={5}>
          {customSortList(this.state.right)}
        </Grid>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //logout: userData => dispatch(logout(userData))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Transfer)
);
