import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    // Link,
    withRouter
} from "react-router-dom";

// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

class Summary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };
    }

    render() {        
        return (
            <Container className='main-panel summary'>
                <Grid container className="header-panel" alignItems="center" mb={1}>
                    <Grid item md={6} xs={6} sx={{ justifyContent:"flex-start" }}>
                        <Typography variant="h6">{!this.props.title?"":this.props.title}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sx={{textAlign:"right"}}>
                        <Button>Refresh</Button>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item md={4} xs={12}><Paper elevation={3}>Sales</Paper></Grid>
                    <Grid item md={4} xs={12}><Paper elevation={3}>Booth</Paper></Grid>
                    <Grid item md={4} xs={12}><Paper elevation={3}>Event</Paper></Grid>
                </Grid>
            </Container>            
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Summary));