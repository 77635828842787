import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCategory, editCategory } from "../redux/actions/categories";
import { convertToBase64 } from "../utils/script";

import {
  TextField,
  Container,
  Grid,
  Typography,
  Button,
  Box,
  Paper,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Switch,
} from "@mui/material";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { styled } from "@mui/material/styles";

class CategoryEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      icon: null,
      base64_icon: null,
      is_active: true,
      id: "",
      slug: "",
    };
  }

  componentDidMount() {
    this.props
      .getCategory({ slug: this.props.match.params.slug })
      .then(async (response) => {
        if (response.code === 200) {
          var base64_icon = null;
          if(response.data.fullurl_icon) {
            base64_icon = await convertToBase64(response.data.fullurl_icon);
          }

          this.setState({
            name: response.data.name,
            base64_icon: base64_icon,
            is_active: response.data.is_active,
            id: response.data.id,
            slug: response.data.slug,
          });
        } else {
          alert("something wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleChange = (e) => {
    let tmpVal = e.target.value;
    if (e.target.name === "is_active") {
      tmpVal = JSON.parse(e.target.checked);
    }
    this.setState({
      [e.target.name]: tmpVal,
    });
  };

  handleSubmit = (e) => {
    var ctx = this;
    let base64_icon = ctx.state.base64_icon.replace(
      "data:application/octet-stream;",
      "data:image/png;"
    );
    e.preventDefault();
    const answer = window.confirm("Are you sure?");
    if (answer) {
      this.props
        .editCategory({
          name: ctx.state.name,
          base64_icon: base64_icon,
          is_active: ctx.state.is_active,
          slug: ctx.state.slug,
        })
        .then((e) => {
          ctx.props.history.replace("/dashboard/category");
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    } else {
      console.log("dismiss!");
    }
  };

  handleChangeFile = (event) => {
    const ctx = this;
    const file = event.target.files[0];
    var reader = new FileReader();

    reader.onloadend = (element) => {
      ctx.setState({
        icon: file,
        base64_icon: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  render() {
    const url = this.props.match.url;
    const Input = styled("input")({
      display: "none",
    });

    return (
      <Container className="main-panel booth">
        <Grid container className="header-panel" alignItems="center" mb={1}>
          <Grid item md={6} xs={6} sx={{ justifyContent: "flex-start" }}>
            <Typography variant="h6">
              {!this.props.title ? "" : this.props.title}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Paper
            component="form"
            className="panel-container-box"
            onSubmit={this.handleSubmit}
          >
            <FormGroup>
              <FormLabel sx={{ marginTop: "12px", marginBottom: "5px" }}>
                Icon Kategori <span className="required">*</span>
              </FormLabel>
              <label htmlFor="image" style={{ width: "fit-content" }}>
                <Input
                  accept="image/*"
                  id="image"
                  name="image"
                  onChange={this.handleChangeFile}
                  multiple
                  type="file"
                />
                {this.state.base64_icon ? (
                  <Box
                    sx={{
                      background: "url(" + this.state.base64_icon + ")",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      height: 150,
                      width: 150,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                  ></Box>
                ) : (
                  <Box
                    sx={{
                      background: "#979797",
                      height: 150,
                      width: 150,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                  >
                    <ImageNotSupportedIcon />
                    <Typography variant="p">Icon not found</Typography>
                  </Box>
                )}
              </label>
              <FormLabel sx={{ marginTop: "12px", marginBottom: "5px" }}>
                Nama Kategori <span className="required">*</span>
              </FormLabel>
              <TextField
                size="small"
                id="input-categoryname"
                name="name"
                label=""
                // helperText="Nama Category"
                placeholder="Nama Kategori"
                value={this.state.name}
                onChange={this.handleChange}
                required
                sx={{ marginBottom: "20px" }}
              />
              <FormControlLabel
                control={<Switch checked={this.state.is_active} />}
                label="Active"
                name="is_active"
                value={this.state.is_active}
                onChange={this.handleChange}
                sx={{ marginBottom: "20px", marginLeft: "5px" }}
              />
              {this.props.permissions.indexOf("create frame") !== -1 ? (
                <Button type="submit" variant="contained">
                  Update
                </Button>
              ) : null}
            </FormGroup>
          </Paper>
        </Box>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCategory: (data) => dispatch(getCategory(data)),
    editCategory: (data) => dispatch(editCategory(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoryEdit)
);
