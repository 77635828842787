import axios from "axios";
import { BASE_URL, BASE_VERSION } from "../config";

export const getCategoryAll = (payload) => {
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "categories/all", {
        params: payload,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};

export const addCategory = (payload) => {
  return (dispatch) => {
    return axios
      .post(
        BASE_URL + BASE_VERSION + "categories",
        {
          name: payload.name,
          base64_icon: payload.base64_icon,
          is_active: payload.is_active,
        },
        {
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
          },
        }
      )
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        console.log(error);
        payload = error;
        return payload;
      });
  };
};

export const getCategory = (payload) => {
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "categories/" + payload.slug, {
        params: payload,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};

export const editCategory = (payload) => {
  return (dispatch) => {
    return axios
      .put(
        BASE_URL + BASE_VERSION + "categories/" + payload.slug,
        {
          name: payload.name,
          base64_icon: payload.base64_icon,
          is_active: payload.is_active ? 1 : 0,
        },
        {
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
          },
        }
      )
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        console.log(error);
        payload = error;
        return payload;
      });
  };
};

export const deleteCategory = (payload) => {
  return (dispatch) => {
    return axios
      .delete(BASE_URL + BASE_VERSION + "categories/" + payload.slug, {
        params: payload,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};
