import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addVideo, syncVideo } from "../redux/actions/video";
import { getBrandAll } from "../redux/actions/brands";
import { getBoothSearch } from "../redux/actions/booths";

import {
  TextField,
  Container,
  Grid,
  Typography,
  Button,
  Box,
  Paper,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  Autocomplete,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { styled } from "@mui/material/styles";

class VideoAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_video: null,
      idbrand: "",
      name: "",
      description: "",
      video: "",
      base64_video: "",
      duration: 15000,
      brands: [],
      booths: [],
      selectedID: [],
      selectedBooth: [],
      boothKeys: [],
      selectAllBooth: false,
      uploading: false,
      is_active: true,
      syncFreame: false,
      progress: 0,
    };
  }

  componentDidMount() {
    const ctx = this;
    this.props
      .getBrandAll()
      .then((e) => {
        if (e.code === 200) {
          ctx.setState({ brands: e.data });
        } else {
          console.log("Something Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const ctx = this;
    if (prevState.idbrand !== this.state.idbrand) {
      this.props
        .getBoothSearch({ idbrand: this.state.idbrand, is_active: 1 })
        .then((respons) => {
          if (respons.code !== 200) {
            ctx.setState({ booths: [] });
          } else {
            ctx.setState({ booths: respons.data });
          }
        })
        .catch((err) => {
          console.log("getBoothSearch: ", err);
        });
    }
  }

  handleChange = (e) => {
    let tmpVal = e.target.value;
    if (e.target.name === "is_active") {
      tmpVal = JSON.parse(e.target.checked);
    }
    this.setState({
      [e.target.name]: tmpVal,
    });
  };

  uploadProgres = (e) => {
    this.setState({ progress: e });
  };

  handleSubmit = (e) => {
    const ctx = this;
    ctx.setState({ uploading: true });
    e.preventDefault();
    const answer = window.confirm("Are you sure?");
    if (answer) {
      ctx.props
        .addVideo({
          name: ctx.state.name,
          description: ctx.state.description,
          duration: ctx.state.duration,
          base64_video: ctx.state.base64_video,
          booths: ctx.state.selectedBooth,
          is_active: ctx.state.is_active ? 1 : 0,
          idbrand: ctx.state.idbrand,
          progress: ctx.uploadProgres,
        })
        .then((e) => {
          ctx.setState({ uploading: false, progress: 0 }, function () {
            if (e.code === 200) {
              if (ctx.state.selectedBooth.length !== 0) {
                const answer = window.confirm(
                  "Do you want to continue video sync?"
                );
                if (answer) {
                  ctx.setState({id_video: e.data.id}, function() {
                    ctx.buttonSync.click();
                  })
                } else {
                  ctx.props.history.replace("/dashboard/video");
                }
              } else {
                ctx.props.history.replace("/dashboard/video");
              }
            } else {
              alert(e.message);
            }
          });
          // ctx.props.history.replace("/dashboard/vi");
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    } else {
      console.log("dismiss!");
    }
  };

  handleChangeFile = (event) => {
    const ctx = this;
    const file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (element) => {
      var media = new Audio(reader.result);
      media.onloadedmetadata = () => {
        if (media.duration) {
          ctx.setState({
            duration: Math.floor(media.duration * 1000),
            video: file,
            base64_video: reader.result,
          });
        } else {
          ctx.setState({
            duration: 15000,
            video: file,
            base64_video: reader.result,
          });
        }
      };
    };
    // reader.readAsArrayBuffer(file);
  };

  handleClick = (event) => {
    if (event.target.checked) {
      this.setState(
        { selectedBooth: this.state.booths, selectAllBooth: true },
        function () {
          let boothKeys = [];
          this.state.selectedBooth.forEach((el) => {
            boothKeys.push(el.boothkey);
          });
          this.setState({ boothKeys });
        }
      );
    } else {
      this.setState({
        selectedBooth: [],
        selectAllBooth: false,
        boothKeys: [],
      });
    }
  };

  handleSync = (e) => {
    e.preventDefault();
    const ctx = this;
    ctx.setState({ syncFreame: true });
    if (ctx.state.boothKeys.length !== 0) {
      if (ctx.state.id_video !== null) {
        const answer = window.confirm("Are you sure to Sync?");
        if (answer) {
          ctx.props
            .syncVideo({
              boothkeys: ctx.state.boothKeys,
              id_ads: ctx.state.id_video,
              progress: ctx.uploadProgres,
            })
            .then((e) => {
              ctx.setState({ syncFreame: false }, function() {
                ctx.props.history.replace("/dashboard/video");
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        alert("Video not found");
      }
    } else {
      alert("Please select booth first !");
    }
  };

  render() {
    const url = this.props.match.url;
    const Input = styled("input")({
      display: "none",
    });

    return (
      <Container className="main-panel booth">
        <Grid container className="header-panel" alignItems="center" mb={1}>
          <Grid item md={6} xs={6} sx={{ justifyContent: "flex-start" }}>
            <Typography variant="h6">
              {!this.props.title ? "" : this.props.title}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Paper
            component="form"
            className="panel-container-box"
            onSubmit={this.handleSubmit}
          >
            <FormGroup>
              {this.state.brands.length !== 0 ? (
                <>
                  <FormLabel sx={{ marginTop: "12px", marginBottom: "5px" }}>
                    Brand <span className="required">*</span>
                  </FormLabel>
                  <Select
                    size="small"
                    labelId="select-brand-label"
                    id="select-brand"
                    name="idbrand"
                    value={this.state.idbrand}
                    label=""
                    onChange={this.handleChange}
                    sx={{ marginBottom: "10px" }}
                  >
                    {this.state.brands.map((item) => {
                      return (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </>
              ) : null}

              {this.state.booths.length !== 0 ? (
                <>
                  <FormLabel sx={{ marginTop: "12px", marginBottom: "5px" }}>
                    Booths <span className="required">*</span>
                  </FormLabel>
                  <Autocomplete
                    multiple
                    size="small"
                    id="checkboxes-tags-demo"
                    ChipProps={{ color: "error" }}
                    onChange={(event, newValue) => {
                      if (this.state.selectAllBooth) {
                        this.buttonElement.click();
                      }

                      this.setState({ selectedBooth: newValue }, () => {
                        let boothKeys = [];
                        newValue.forEach((el) => {
                          boothKeys.push(el.boothkey);
                        });
                        this.setState({ boothKeys });
                      });
                    }}
                    autoHighlight
                    disableCloseOnSelect
                    defaultValue={this.state.selectedID.map(
                      (item) => this.state.booths[item]
                    )}
                    options={this.state.booths}
                    value={this.state.selectedBooth}
                    filterSelectedOptions
                    sx={{ marginBottom: "5px" }}
                    getOptionLabel={(option) => option?.name}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <li
                          {...props}
                          style={{ padding: "0px" }}
                          data-idbooth={option.id}
                        >
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        placeholder="Booths"
                        helperText="Pilih Booth"
                      />
                    )}
                  />
                  <FormGroup>
                    <FormControlLabel
                      sx={{ marginBottom: "15px" }}
                      ref={(button) => (this.buttonElement = button)}
                      onClick={this.handleClick}
                      control={<Checkbox />}
                      label="Select All Booth"
                    />
                  </FormGroup>
                </>
              ) : null}

              <FormLabel sx={{ marginTop: "12px", marginBottom: "5px" }}>
                Nama Video <span className="required">*</span>
              </FormLabel>
              <TextField
                size="small"
                id="input-categoryname"
                name="name"
                label=""
                placeholder="Nama Video"
                value={this.state.name}
                onChange={this.handleChange}
                required
                sx={{ marginBottom: "20px" }}
              />
              <FormLabel sx={{ marginTop: "12px", marginBottom: "5px" }}>
                Deskripsi Video <span className="required">*</span>
              </FormLabel>
              <TextField
                size="small"
                id="input-categoryname"
                name="description"
                label=""
                rows={3}
                multiline
                placeholder="Deskripsi Video"
                value={this.state.description}
                onChange={this.handleChange}
                required
                sx={{ marginBottom: "20px" }}
              />
              <FormLabel sx={{ marginTop: "12px", marginBottom: "5px" }}>
                File Video <span className="required">*</span>
              </FormLabel>
              {this.state.base64_video !== "" ? (
                <video
                  src={this.state.base64_video}
                  width={350}
                  height={197}
                  alt="video_preview"
                  controls
                />
              ) : (
                <Box
                  sx={{
                    height: 197,
                    width: 350,
                    background: "#979797",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <VideocamOffIcon />
                  <Typography variant="p">Video not found</Typography>
                </Box>
              )}
              <label htmlFor="fileurl" style={{ width: "fit-content" }}>
                <Input
                  accept="video/*"
                  id="fileurl"
                  name="fileurl"
                  onChange={this.handleChangeFile}
                  multiple
                  type="file"
                />
                <Button
                  variant="outlined"
                  color="primary"
                  component="span"
                  sx={{
                    width: "auto",
                    marginTop: "10px",
                    textTransform: "none",
                  }}
                >
                  Upload video
                </Button>
              </label>
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Active"
                name="is_active"
                value={this.state.is_active}
                onChange={this.handleChange}
                sx={{ margin: "20px", marginLeft: "0px" }}
              />
              {this.state.uploading !== false ? (
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <CircularProgress
                    variant="determinate"
                    value={this.state.progress}
                  />
                </Box>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  disabled={
                    this.props.permissions.indexOf("create ads") !== -1
                      ? false
                      : true
                  }
                >
                  Create Video
                </Button>
              )}
              {this.state.syncFreame !== false ? (
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={this.state.progress}
                  />
                </Box>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleSync}
                  sx={{ marginTop: "20px" }}
                  ref={(button) => (this.buttonSync = button)}
                  disabled={
                    this.props.permissions.indexOf("create ads") !== -1
                      ? false
                      : true
                  }
                >
                  Sync Video
                </Button>
              )}
            </FormGroup>
          </Paper>
        </Box>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getBrandAll: (data) => dispatch(getBrandAll(data)),
    getBoothSearch: (data) => dispatch(getBoothSearch(data)),
    addVideo: (data) => dispatch(addVideo(data)),
    syncVideo: (data) => dispatch(syncVideo(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoAdd)
);
