import React, { Component } from "react";
import { connect } from "react-redux";
import {
  // Link,
  withRouter,
} from "react-router-dom";

import { addBrand, brandSlug } from "../redux/actions/brands";

import {
  Box,
  // Grid,
  Paper,
  Switch,
  Select,
  Button,
  Container,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  TextField,
  FormLabel,
} from "@mui/material";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { styled } from "@mui/material/styles";

class BrandAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: null,
      data: {
        name: "",
        slug: "",
        description: "",
        base64_image: null,
        image: null,
        is_active: true,
      },
      err: null,
      slug: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    let tmpVal = e.target.value;
    if (e.target.name === "is_active") {
      tmpVal = JSON.parse(e.target.checked);
    }
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: tmpVal,
      },
    });
  }

  handleChangeFile = (event) => {
    const ctx = this;
    const file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (element) => {
      ctx.setState({
        image: file,
        base64_image: reader.result,
      });
    };
  };

  componentDidMount() {
    this.props
      .brandSlug()
      .then((e) => {
        if (e.code === 200) {
          if (e.data !== null) {
            this.setState({ slug: e.data });
          } else {
            this.setState({ err: e.message });
          }
        } else {
          this.setState({ err: e.message });
        }
      })
      .catch((e) => {
        alert("Something Error");
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    const answer = window.confirm("Are you sure?");
    if (answer) {
      var ctx = this;
      this.props
        .addBrand({
          name: ctx.state.data.name,
          slug: ctx.state.data.slug,
          description: ctx.state.data.description,
          is_active: ctx.state.data.is_active,
          base64_image: ctx.state.base64_image,
        })
        .then((e) => {
          ctx.props.history.replace("/dashboard/brand");
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    } else {
      console.log("dismiss!");
    }
  }

  render() {
    const Input = styled("input")({
      display: "none",
    });

    return (
      <Container className="main-panel brand">
        <Box mt={3}>
          <Paper
            component="form"
            className="panel-container-box"
            onSubmit={this.handleSubmit}
          >
            <Typography variant="h5" mt={1} mb={2}>
              Create New Brand
            </Typography>
            <FormGroup>
              <TextField
                size="small"
                id="input-brandname"
                name="name"
                label="Brand Name"
                helperText="Nama Brand"
                value={this.state.data.name}
                onChange={this.handleChange}
                required
                sx={{ marginBottom: "20px" }}
              />

              {/* <TextField
                size="small"
                id="input-brandslug"
                name="slug"
                label="Brand Slug"
                helperText="Nama Slug"
                value={this.state.data.slug}
                onChange={this.handleChange}
                required
                sx={{ marginBottom: "20px" }}
                inputProps={{ style: { textTransform: "lowercase" } }}
              /> */}
              <FormControl size="small" sx={{ marginBottom: "20px" }}>
                <InputLabel id="select-perpage-label">Brand Slug *</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  label="Brand Slug *"
                  name="slug"
                  id="select-order"
                  labelId="select-order-label"
                  onChange={this.handleChange}
                  value={this.state.data.slug}
                  // sx={{ width: "100px" }}
                >
                  {this.state.slug.map((item, idx) => {
                    return (
                      <MenuItem value={item.slug} key={idx}>
                        {item.slug}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>Brand Slug</FormHelperText>
              </FormControl>

              <TextField
                size="small"
                id="input-branddescription"
                name="description"
                label="Brand Description"
                helperText="Deskripsi Brand"
                value={
                  this.state.data.description !== null
                    ? this.state.data.description
                    : ""
                }
                onChange={this.handleChange}
                multiline
                rows={3}
                sx={{ marginBottom: "20px" }}
              />

              <FormLabel sx={{ marginTop: "12px", marginBottom: "5px" }}>
                How to Image
              </FormLabel>
              {this.state.base64_image ? (
                <label htmlFor="image" style={{ width: "fit-content" }}>
                  <Input
                    accept="image/*"
                    id="image"
                    name="image"
                    onChange={this.handleChangeFile}
                    multiple
                    type="file"
                  />
                  <Box
                    sx={{
                      background: "url(" + this.state.base64_image + ")",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      height: 197,
                      width: 350,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                  ></Box>
                </label>
              ) : (
                <label htmlFor="image" style={{ width: "fit-content" }}>
                  <Input
                    accept="image/*"
                    id="image"
                    name="image"
                    onChange={this.handleChangeFile}
                    multiple
                    type="file"
                  />
                  <Box
                    sx={{
                      background: "#979797",
                      height: 197,
                      width: 350,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                  >
                    <ImageNotSupportedIcon />
                    <Typography variant="p">Image not found</Typography>
                  </Box>
                </label>
              )}
              <FormHelperText sx={{ marginBottom: "12px" }}>
                aspect ratio 16 : 9
              </FormHelperText>

              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Active"
                name="is_active"
                value={this.state.data?.is_active}
                onChange={this.handleChange}
                sx={{ marginBottom: "20px", marginLeft: "5px" }}
              />
              {this.props.permissions.indexOf("create brand") !== -1 ? (
                <Button type="submit" variant="contained">
                  Create
                </Button>
              ) : null}
            </FormGroup>
          </Paper>
        </Box>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addBrand: (data) => dispatch(addBrand(data)),
    brandSlug: () => dispatch(brandSlug()),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BrandAdd)
);
