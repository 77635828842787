import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getBrand, updateBrand, deleteBrand } from "../redux/actions/brands";
import { convertToBase64 } from "../utils/script";

import {
  Box,
  Paper,
  Switch,
  Button,
  Container,
  Typography,
  FormControlLabel,
  FormGroup,
  TextField,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { styled } from "@mui/material/styles";

class BrandEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: null,
      data: {
        id: "",
        name: "",
        description: "",
        image: null,
        base64_image: null,
        is_active: true,
      },
      err: null,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleDelete(e) {
    e.preventDefault();
    const answer = window.confirm("Are you sure to delete?");
    if (answer) {
      var ctx = this;
      this.props
        .deleteBrand({ id: this.state.data.id })
        .then((e) => {
          if (e.code === 200) {
            ctx.props.history.replace("/dashboard/brand");
          } else {
            alert("Something Error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  handleChange(e) {
    let tmpVal = e.target.value;
    if (e.target.name === "is_active") {
      tmpVal = JSON.parse(e.target.checked);
    }
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: tmpVal,
      },
    });
  }

  handleChangeFile = (event) => {
    const ctx = this;
    const file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (element) => {
      ctx.setState({
        image: file,
        base64_image: reader.result,
      });
    };
  };

  handleSubmit(e) {
    e.preventDefault();
    var ctx = this;
    let base64_image = ctx.state.base64_image.replace(
      "data:application/octet-stream;",
      "data:image/jpg;"
    );
    const answer = window.confirm("Are you sure?");
    if (answer) {
      this.props
        .updateBrand({
          id: ctx.state.data.id,
          name: ctx.state.data.name,
          description: ctx.state.data.description,
          is_active: ctx.state.data.is_active,
          base64_image: base64_image,
        })
        .then((e) => {
          ctx.props.history.replace("/dashboard/brand");
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    } else {
      console.log("dismiss!");
    }
  }

  componentDidMount() {
    var ctx = this;
    this.props
      .getBrand({ id: this.props.match.params.id })
      .then((e) => {
        if (e.code === 200) {
          ctx.setState({ data: e.data }, async function () {
            if (e.data.fullurl_image) {
              const base64_image = await convertToBase64(e.data.fullurl_image);
              ctx.setState({
                ...ctx.state.data,
                base64_image: base64_image,
                image: e.data.fullurl_image,
              });
            }
          });
        } else {
          alert("Something Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const Input = styled("input")({
      display: "none",
    });

    return (
      <Container className="main-panel brand">
        <Box mt={3}>
          <Paper
            component="form"
            className="panel-container-box"
            onSubmit={this.handleSubmit}
          >
            <Typography variant="h5" mt={1} mb={2}>
              Edit Brand
            </Typography>
            <FormGroup>
              <TextField
                size="small"
                id="input-brandname"
                name="name"
                label="Brand Name"
                helperText="Nama Brand"
                value={this.state.data.name}
                onChange={this.handleChange}
                required
                sx={{ marginBottom: "20px" }}
              />

              <TextField
                size="small"
                id="input-branddescription"
                name="description"
                label="Brand Description"
                helperText="Deskripsi Brand"
                value={
                  this.state.data.description !== null
                    ? this.state.data.description
                    : ""
                }
                onChange={this.handleChange}
                multiline
                rows={3}
                sx={{ marginBottom: "20px" }}
              />

              <FormLabel sx={{ marginTop: "5px", marginBottom: "5px" }}>
                How to Image
              </FormLabel>
              {this.state.base64_image ? (
                <label htmlFor="image" style={{ width: "fit-content" }}>
                  <Input
                    accept="image/*"
                    id="image"
                    name="image"
                    onChange={this.handleChangeFile}
                    multiple
                    type="file"
                  />
                  <Box
                    sx={{
                      background: "url(" + this.state.base64_image + ")",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      height: 197,
                      width: 350,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                  ></Box>
                </label>
              ) : (
                <label htmlFor="image" style={{ width: "fit-content" }}>
                  <Input
                    accept="image/*"
                    id="image"
                    name="image"
                    onChange={this.handleChangeFile}
                    multiple
                    type="file"
                  />
                  <Box
                    sx={{
                      background: "#979797",
                      height: 197,
                      width: 350,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                  >
                    <ImageNotSupportedIcon />
                    <Typography variant="p">Image not found</Typography>
                  </Box>
                </label>
              )}
              <FormHelperText sx={{ marginBottom: "12px" }}>
                aspect ratio 16 : 9
              </FormHelperText>

              <FormControlLabel
                control={<Switch checked={this.state.data.is_active} />}
                label="Active"
                name="is_active"
                value={this.state.data.is_active}
                onChange={this.handleChange}
                sx={{ marginBottom: "20px", marginLeft: "5px" }}
              />

              {this.props.permissions.indexOf("edit brand") !== -1 ? (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ marginBottom: "10px" }}
                >
                  Update
                </Button>
              ) : null}
              {this.props.permissions.indexOf("delete brand") !== -1 ? (
                <Button
                  onClick={this.handleDelete}
                  variant="contained"
                  color="error"
                >
                  Delete
                </Button>
              ) : null}
            </FormGroup>
          </Paper>
        </Box>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getBrand: (data) => dispatch(getBrand(data)),
    updateBrand: (data) => dispatch(updateBrand(data)),
    deleteBrand: (data) => dispatch(deleteBrand(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BrandEdit)
);
