export const toggleMenuON = () => {
    return (dispatch) => {
        dispatch({ type: 'MENU_ON' });
        return true;      
    }
}
export const toggleMenuOFF = () => {
    return (dispatch) => {
        dispatch({ type: 'MENU_OFF' });
        return true;      
    }
}