import React, { Component } from "react";
import { connect } from "react-redux";
import {
  // Link,
  withRouter,
} from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";

import { getBrandAll } from "../redux/actions/brands";

// import Box from '@mui/material/Box';
import {
  Select,
  MenuItem,
  // TextField,
  InputLabel,
  FormControl,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from '@mui/material/TablePagination';
import TableRow from "@mui/material/TableRow";

class BrandAll extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies),
  };
  constructor(props) {
    super(props);
    this.state = {
      filter_perpage: 0,
      filter_order: "",
      filter_sort: "",
      perpage: [30, 50, 100, 250, 500, 1000],
      data: [],
      orderBy: [
        { id: "name", name: "Name" },
        { id: "slug", name: "Slug" },
      ],
      sortBy: [
        { id: "ASC", name: "Ascending" },
        { id: "DESC", name: "Descending" },
      ],
      err: null,
      is_loading: false,
      page: 1,
      hasMore: true,
    };
  }

  fetchCookies = () => {
    var brand_cookies = this.props.cookies.get("brand_cookies");
    if (brand_cookies !== undefined) {
      this.setState(
        {
          filter_perpage: brand_cookies.filter_perpage,
          filter_order: brand_cookies.filter_order,
          filter_sort: brand_cookies.filter_sort,
        },
        () => {
          this.buttonElement.click();
        }
      );
    }
  };

  componentDidUpdate(prevProv, prevState) {
    if (prevProv.cookies !== this.props.cookies) {
      this.fetchCookies();
    }
  }

  handleFilterSubmit = (e) => {
    this.setState({ is_loading: true });
    e.preventDefault();
    const doc = document.getElementsByClassName("infinite-scroll-component");
    if (doc[0]) {
      doc[0].scrollTo({
        top: 0,
        // behavior: "smooth",
      });
    }

    const { cookies } = this.props;
    let expires = new Date();
    let data = {
      filter_perpage: this.state.filter_perpage,
      filter_order: this.state.filter_order,
      filter_sort: this.state.filter_sort,
    };
    expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);

    cookies.set("brand_cookies", data, { path: "/", expires: expires });

    let params = {};
    if (this.state.filter_order !== "") {
      params.order = this.state.filter_order;
    }
    if (this.state.filter_sort !== "") {
      params.sort = this.state.filter_sort;
    }

    params.perpage = this.state.perpage[this.state.filter_perpage];
    params.page = 1;

    this.props
      .getBrandAll(params)
      .then((e) => {
        if (e.code === 200) {
          if (e.data !== null) {
            this.setState({
              data: e.data,
              hasMore:
                e.data.length !== this.state.perpage[this.state.filter_perpage]
                  ? false
                  : true,
              is_loading: false,
              page: 1,
            });
          } else {
            this.setState({
              data: [],
              hasMore: false,
              is_loading: false,
              page: 1,
            });
          }
        } else {
          this.setState({
            err: e.message,
            hasMore: false,
            is_loading: false,
            page: 1,
          });
        }
      })
      .catch((e) => {
        alert("Something Error");
      });
  };

  handleFilterClear = (e) => {
    this.setState(
      {
        filter_perpage: 0,
        filter_order: "",
        filter_sort: "",
      },
      () => {
        this.props.cookies.remove("brand_cookies", { path: "/", expires: 0 });
        var ctx = this;
        this.props
          .getBrandAll({
            perpage: this.state.perpage[this.state.filter_perpage],
            page: this.state.page,
          })
          .then((e) => {
            if (e.code === 200) {
              if (e.data !== null) {
                ctx.setState({
                  data: e.data,
                  hasMore:
                    e.data.length !==
                    this.state.perpage[this.state.filter_perpage]
                      ? false
                      : true,
                });
              } else {
                ctx.setState({ data: [], hasMore: false });
              }
            } else {
              ctx.setState({ err: e.message, hasMore: false });
            }
          })
          .catch((e) => {
            alert("Something Error");
          });
      }
    );
  };

  componentDidMount() {
    var ctx = this;
    this.props
      .getBrandAll({
        perpage: this.state.perpage[this.state.filter_perpage],
        page: this.state.page,
      })
      .then((e) => {
        if (e.code === 200) {
          if (e.data !== null) {
            ctx.setState({
              data: e.data,
              hasMore:
                e.data.length !== this.state.perpage[this.state.filter_perpage]
                  ? false
                  : true,
            });
          } else {
            ctx.setState({ data: [], hasMore: false });
          }
        } else {
          ctx.setState({ err: e.message, hasMore: false });
        }
      })
      .catch((e) => {
        alert("Something Error");
      });

    this.fetchCookies();
  }

  handleChangeFilter = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  loadFunc = () => {
    const ctx = this;
    const nextpage = this.state.page + 1;
    this.props
      .getBrandAll({
        perpage: this.state.perpage[this.state.filter_perpage],
        page: nextpage,
      })
      .then((e) => {
        if (e.code === 200) {
          if (e.data !== null) {
            setTimeout(() => {
              ctx.setState({
                data: this.state.data.concat(e.data),
                hasMore:
                  e.data.length !==
                  this.state.perpage[this.state.filter_perpage]
                    ? false
                    : true,
                page: nextpage,
              });
            }, 1500);
          } else {
            ctx.setState({ hasMore: false, page: nextpage });
          }
        } else {
          ctx.setState({ err: e.message, hasMore: false, page: nextpage });
        }
      })
      .catch((e) => {
        alert("Something Error");
      });
  };

  render() {
    const url = this.props.match.url;
    var tableBody;

    if (this.state.data !== null) {
      tableBody = this.state.data.map((item, idx) => {
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
            <TableCell sx={{ width: "1%", whiteSpace: "nowrap" }}>
              {(idx += 1)}
            </TableCell>
            <TableCell align="left">{item.name}</TableCell>
            <TableCell align="left">{item.slug}</TableCell>
            <TableCell align="left">{item.description}</TableCell>
            <TableCell
              align="center"
              sx={{ width: "1%", whiteSpace: "nowrap" }}
            >
              {item.is_active === true ? "Active" : "Not Active"}
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: "70px", whiteSpace: "nowrap" }}
            >
              <Grid container gap={1} alignItems="center">
                <Button
                  variant="contained"
                  color="warning"
                  disabled={
                    this.props.permissions.indexOf("edit brand") !== -1
                      ? false
                      : true
                  }
                  onClick={(e) => {
                    this.props.history.push(url + "/edit/" + item.id);
                  }}
                >
                  Edit
                </Button>
              </Grid>
            </TableCell>
          </TableRow>
        );
      });
    }

    return (
      <Container className="main-panel booth">
        <Grid container className="header-panel" alignItems="center" mb={1}>
          <Grid item md={6} xs={6} sx={{ justifyContent: "flex-start" }}>
            <Typography variant="h6">
              {!this.props.title ? "" : this.props.title}
            </Typography>
          </Grid>
          {this.props.permissions.indexOf("create brand") !== -1 ? (
            <Grid item md={6} xs={6} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  this.props.history.push(url + "/add");
                }}
              >
                Add Brand
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          container
          className="header-panel"
          alignItems="center"
          sx={{
            paddingTop: "10px",
            paddingBottom: "10px",
            marginBottom: "10px",
          }}
        >
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              display: "flex",
              gap: "5px",
              justifyContent: "flex-start",
              flexWrap: { xs: "wrap", md: "nowrap" },
            }}
          >
            <FormControl>
              <InputLabel id="select-perpage-label">Per Page</InputLabel>
              <Select
                size="small"
                label="Perpage"
                name="filter_perpage"
                id="select-perpage"
                labelId="select-perpage-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_perpage}
                sx={{ width: "100px" }}
              >
                {this.state.perpage.map((item, idx) => {
                  return (
                    <MenuItem value={idx} key={idx}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-perpage-label">Order By</InputLabel>
              <Select
                size="small"
                label="Order By"
                name="filter_order"
                id="select-order"
                labelId="select-order-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_order}
                sx={{ width: "100px" }}
              >
                {this.state.orderBy.map((item, idx) => {
                  return (
                    <MenuItem value={item.id} key={idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-perpage-label">Sort By</InputLabel>
              <Select
                size="small"
                label="Sort By"
                name="filter_sort"
                id="select-sort"
                labelId="select-sort-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_sort}
                sx={{ width: "100px" }}
              >
                {this.state.sortBy.map((item, idx) => {
                  return (
                    <MenuItem value={item.id} key={idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {!this.state.is_loading ? (
              <Button
                ref={(button) => (this.buttonElement = button)}
                variant="contained"
                onClick={this.handleFilterSubmit}
              >
                Filter
              </Button>
            ) : (
              <Typography className="btn-loading-filter">
                HARAP SABAR...
              </Typography>
            )}
            <Button variant="contained" onClick={this.handleFilterClear}>
              Clear Filter
            </Button>
          </Grid>
        </Grid>
        <Paper>
          <TableContainer sx={{ maxHeight: 570 }}>
            <InfiniteScroll
              // id="infinite-scroll-component"
              height={500}
              dataLength={this.state.data.length}
              next={this.loadFunc}
              hasMore={this.state.hasMore}
              loader={
                <Typography
                  id="load-data"
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    padding: "15px",
                  }}
                >
                  Loading...
                </Typography>
              }
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Slug</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tableBody}</TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </Paper>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getBrandAll: (data) => dispatch(getBrandAll(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(BrandAll))
);
