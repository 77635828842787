import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { toggleMenuOFF } from "../redux/actions/frontend";

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      err: null,
      expand: false,
    };

    this.handleCloseMenu = this.handleCloseMenu.bind(this);
  }

  handleCloseMenu(e) {
    // e.preventDefault();
    this.props.toggleMenuOFF();
  }

  componentDidMount() {
    if (this.props.userData.roles === "admin") {
      this.setState({ isAdmin: true });
    }
    if (
      this.props.location.pathname === "/dashboard/frame" ||
      this.props.location.pathname === "/dashboard/category"
    ) {
      this.setState({ expand: true });
    } else {
      this.setState({ expand: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (
        this.props.location.pathname === "/dashboard/frame" ||
        this.props.location.pathname === "/dashboard/category"
      ) {
        this.setState({ expand: true });
      } else {
        this.setState({ expand: false });
      }
    }
  }

  render() {
    const url = this.props.match.url;

    const theme = createTheme({
      components: {
        MuiButtonBase: {
          styleOverrides: {
            root: {
              padding: "0px !important",
              minHeight: "39px !important",
              "&.Mui-expanded": {
                padding: "0px !important",
                minHeight: "39px !important",
              },
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            root: {
              minHeight: "48px",
              padding: "0px !important",
              "&.Mui-expanded": {
                padding: "0px !important",
                minHeight: "48px !important",
              },
            },
            content: {
              margin: "10px 0px !important",
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              background: "#b2b2b2",
              minHeight: "39px !important",
              // maxHeight: "39px !important",
              paddingTop: "0px !important",
              paddingBottom: "0px !important",
              boxShadow: "unset !important",
              borderBottom: "1px solid #c5c5c5 !important",
            },
          },
        },
        MuiAccordionDetails: {
          styleOverrides: {
            root: {
              paddingTop: "0px",
              paddingBottom: "5px",
            },
          },
        },
      },
    });

    return (
      <Box className="main-side-menu">
        <ul>
          <li>
            <Link to="/home" onClick={this.handleCloseMenu}>
              Home
            </Link>
          </li>
          {this.props.permissions.indexOf("read brand") !== -1 ? (
            <li>
              <Link to={`${url}/brand`} onClick={this.handleCloseMenu}>
                Brands
              </Link>
            </li>
          ) : null}
          {this.props.permissions.indexOf("read booth") !== -1 ? (
            <li>
              <Link to={`${url}/booth`} onClick={this.handleCloseMenu}>
                Booths
              </Link>
            </li>
          ) : null}
          {this.props.permissions.indexOf("read frame") !== -1 ? (
            <li>
              <ThemeProvider theme={theme}>
                <Accordion
                  square={true}
                  expanded={this.state.expand}
                  onChange={(e) => {
                    this.setState({ expand: !this.state.expand });
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon size="small" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Frames</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Link to={`${url}/frame`} onClick={this.handleCloseMenu}>
                      Lanscape Frames
                    </Link>
                    <Link to={`${url}/framev2`} onClick={this.handleCloseMenu}>
                      Portrait Frames
                    </Link>
                    <Link to={`${url}/category`} onClick={this.handleCloseMenu}>
                      Categories
                    </Link>
                  </AccordionDetails>
                </Accordion>
              </ThemeProvider>
              {/* <Link to={`${url}/frame`} onClick={this.handleCloseMenu}>
                Frames
              </Link> */}
            </li>
          ) : null}
          {this.props.permissions.indexOf("read photo") !== -1 ? (
            <li>
              <Link to={`${url}/photo`} onClick={this.handleCloseMenu}>
                Photo
              </Link>
            </li>
          ) : null}
           {this.props.permissions.indexOf("read photo") !== -1 ? (
            <li>
              <Link to={`${url}/gif`} onClick={this.handleCloseMenu}>
                Gif
              </Link>
            </li>
          ) : null}
          {this.props.permissions.indexOf("read coupon") !== -1 ? (
            <li>
              <Link to={`${url}/coupon`} onClick={this.handleCloseMenu}>
                Vouchers
              </Link>
            </li>
          ) : null}
          {this.props.permissions.indexOf("read transaction") !== -1 ? (
            <li>
              <Link to={`${url}/sales`} onClick={this.handleCloseMenu}>
                Sales
              </Link>
            </li>
          ) : null}
          {this.props.permissions.indexOf("read ads") !== -1 ? (
            <li>
              <Link to={`${url}/video`} onClick={this.handleCloseMenu}>
                Video
              </Link>
            </li>
          ) : null}
          {this.props.permissions.indexOf("read filter") !== -1 ? (
            <li>
              <Link to={`${url}/filter`} onClick={this.handleCloseMenu}>
                Filter
              </Link>
            </li>
          ) : null}
          {this.props.permissions.indexOf("read package") !== -1 ? (
            <li>
              <Link to={`${url}/package`} onClick={this.handleCloseMenu}>
                Package
              </Link>
            </li>
          ) : null}
          {/* {this.state.isAdmin ? (
            <li>
              <Link to={`${url}/sales`} onClick={this.handleCloseMenu}>
                Sales
              </Link>
            </li>
          ) : (
            ""
          )} */}
          {/* <li><Link to={`${url}/library`}>Libraries</Link></li> */}
          {/* <li><Link to={`${url}/setting`}>Settings</Link></li> */}
          {/* <li><Link to={`${url}/remote`}>Remote</Link></li> */}
        </ul>
      </Box>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleMenuOFF: (userData) => dispatch(toggleMenuOFF(userData)),
    //logout: userData => dispatch(logout(userData))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideMenu)
);
