import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import { CSVLink } from "react-csv";

import { format, formatDistanceToNow, isBefore } from "date-fns";

import { getCouponAll, getCouponSearch } from "../redux/actions/coupons";
import { getUserAll } from "../redux/actions/users";

import {
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  capitalize,
} from "@mui/material";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from '@mui/material/TablePagination';
import TableRow from "@mui/material/TableRow";

class CouponAll extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies),
  };
  constructor(props) {
    super(props);
    this.state = {
      filter_code: "",
      filter_category: 0,
      filter_type: 0,
      filter_iduser: 0,
      filter_description: "",
      filter_perpage: 0,
      filter_order: "",
      filter_sort: "",
      perpage: [30, 50, 100, 250, 500, 1000, 3000],
      categories: ["retake", "operator", "ops", "event", "booth", "activation"],
      type: ["free", "amount", "percentage"],
      users: [],
      data: [],
      dataExport: [],
      orderBy: [
        { id: "code", name: "Code" },
        { id: "valid_end", name: "Expired" },
        { id: "qty", name: "Quantity" },
      ],
      sortBy: [
        { id: "ASC", name: "Ascending" },
        { id: "DESC", name: "Descending" },
      ],
      err: null,
      is_loading: false,
      page: 1,
      hasMore: true,
    };

    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
  }

  handleChangeFilter(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleFilterSubmit(e) {
    this.setState({ is_loading: true });
    e.preventDefault();
    const doc = document.getElementsByClassName("infinite-scroll-component");
    if (doc[0]) {
      doc[0].scrollTo({
        top: 0,
        // behavior: "smooth",
      });
    }

    let params = {};
    if (this.state.filter_code !== "") {
      params.code = this.state.filter_code;
    }
    if (this.state.filter_category !== 0) {
      params.coupon_category = this.state.filter_category;
    }
    if (this.state.filter_type !== 0) {
      params.coupon_type = this.state.filter_type;
    }
    if (this.state.filter_iduser !== 0) {
      params.iduser = this.state.filter_iduser;
    }
    if (this.state.filter_description !== "") {
      params.description = this.state.filter_description;
    }
    if (this.state.filter_order !== "") {
      params.order = this.state.filter_order;
    }
    if (this.state.filter_sort !== "") {
      params.sort = this.state.filter_sort;
    }

    const { cookies } = this.props;
    let expires = new Date();
    let data = {
      filter_code: this.state.filter_code,
      filter_category: this.state.filter_category,
      filter_type: this.state.filter_type,
      filter_iduser: this.state.filter_iduser,
      filter_description: this.state.filter_description,
      filter_perpage: this.state.filter_perpage,
      filter_order: this.state.filter_order,
      filter_sort: this.state.filter_sort,
    };
    expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);

    cookies.set("coupon_cookies", data, { path: "/", expires: expires });

    params.perpage = this.state.perpage[this.state.filter_perpage];
    params.page = 1;

    this.props.getCouponAll(params).then((e) => {
      if (e.data !== null) {
        this.setState({
          data: e.data,
          is_loading: false,
          hasMore: e.data.length !== params.perpage ? false : true,
          page: 1,
        });
      } else {
        this.setState({
          data: [],
          is_loading: false,
          hasMore: false,
          page: 1,
        });
      }
    });
  }

  componentDidUpdate(prevProv, prevState) {
    if (prevProv.cookies !== this.props.cookies) {
      this.fetchCookies();
    }
  }

  componentDidMount() {
    var ctx = this;
    this.props.getUserAll().then((e) => {
      this.setState({ users: e.data });
    });

    this.props
      .getCouponAll({
        perpage: this.state.perpage[this.state.filter_perpage],
        page: this.state.page,
      })
      .then(async (e) => {
        if (e.code === 200) {
          if (e.data !== null) {
            ctx.setState({ data: e.data });
          } else {
            ctx.setState({ data: [], hasMore: false });
          }
        } else {
          ctx.setState({ err: e.message });
        }
        await this.fetchCookies();
      })
      .catch((e) => {
        alert("Something Error");
      });
  }

  fetchCookies = () => {
    var coupon_cookies = this.props.cookies.get("coupon_cookies");
    if (coupon_cookies !== undefined) {
      this.setState(
        {
          filter_code: coupon_cookies.filter_code,
          filter_category: coupon_cookies.filter_category,
          filter_type: coupon_cookies.filter_type,
          filter_iduser: coupon_cookies.filter_iduser,
          filter_description: coupon_cookies.filter_description,
          filter_perpage: coupon_cookies.filter_perpage,
          filter_order: coupon_cookies.filter_order,
          filter_sort: coupon_cookies.filter_sort,
        },
        () => {
          this.buttonElement.click();
        }
      );
    }
  };

  handleFilterClear = (e) => {
    this.setState(
      {
        filter_code: "",
        filter_category: 0,
        filter_type: 0,
        filter_iduser: 0,
        filter_description: "",
        filter_perpage: 0,
        filter_order: "",
        filter_sort: "",
      },
      () => {
        this.props.cookies.remove("coupon_cookies", { path: "/", expires: 0 });
        this.props
          .getCouponAll({
            perpage: this.state.perpage[this.state.filter_perpage],
            page: this.state.page,
          })
          .then((e) => {
            if (e.code === 200) {
              if (e.data !== null) {
                this.setState({ data: e.data });
              } else {
                this.setState({ data: [], hasMore: false });
              }
            } else {
              this.setState({ err: e.message });
            }
          })
          .catch((e) => {
            alert("Something Error");
          });
      }
    );
  };

  loadFunc = () => {
    const ctx = this;
    const nextpage = this.state.page + 1;
    this.props
      .getCouponAll({
        perpage: this.state.perpage[this.state.filter_perpage],
        page: nextpage,
      })
      .then((e) => {
        if (e.code === 200) {
          ctx.setState({
            page: nextpage,
          });
          if (e.data !== null) {
            setTimeout(() => {
              ctx.setState(
                {
                  data: this.state.data.concat(e.data),
                },
                function () {
                  if (
                    e.data.length !==
                    this.state.perpage[this.state.filter_perpage]
                  ) {
                    this.setState({ hasMore: false });
                  }
                }
              );
            }, 1500);
          } else {
            this.setState({ hasMore: false });
          }
        } else {
          ctx.setState({ err: e.message });
        }
      })
      .catch((e) => {
        alert("Something Error");
      });
  };

  render() {
    let dataExport = [];
    const url = this.props.match.url;
    var tableBody;
    if (this.state.data.length !== 0) {
      let record_to_download = {};
      tableBody = this.state.data.map((item, idx) => {
        dataExport.push({
          ...item,
          no: idx + 1,
          expired_at: `${
            (isBefore(new Date(item.valid_end.replace(/-/g, "/")), new Date())
              ? "Expired "
              : "") +
            formatDistanceToNow(new Date(item.valid_end.replace(/-/g, "/")), {
              addSuffix: true,
            })
          }`,
          valid_date: `${format(
            new Date(item.valid_start.replace(/-/g, "/")),
            "HH:mm - dd/MMM/yyyy"
          )} s/d ${format(
            new Date(item.valid_end.replace(/-/g, "/")),
            "HH:mm - dd/MMM/yyyy"
          )}`,
        });
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
            <TableCell
              align="center"
              sx={{ width: "1%", whiteSpace: "nowrap" }}
            >
              {(idx += 1)}
            </TableCell>
            <TableCell align="center">
              <Typography
                variant="body2"
                sx={{ display: "block", fontWeight: "bold" }}
              >
                {item.code}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                variant="body2"
                sx={{ display: "block", textTransform: "uppercase" }}
              >
                {item.applied_booth ? item.applied_booth.name : 'All'}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                variant="body2"
                sx={{ display: "block", textTransform: "uppercase" }}
              >
                {item.coupon_type}
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: "1%", whiteSpace: "nowrap" }}
            >
              {item.qty}
            </TableCell>
            <TableCell align="left" sx={{ width: "1%", whiteSpace: "nowrap" }}>
              {item.description}
            </TableCell>
            <TableCell align="center">
              {isBefore(
                new Date(item.valid_end.replace(/-/g, "/")),
                new Date()
              ) ? (
                <Typography sx={{ display: "block", color: "#ff0000" }}>
                  Expired
                </Typography>
              ) : (
                ""
              )}
              {formatDistanceToNow(
                new Date(item.valid_end.replace(/-/g, "/")),
                { addSuffix: true }
              )}
              {/* {formatDistance(subDays(new Date(item.valid_end.replace(/-/g, "/")), 0), new Date(), { addSuffix: true })} */}
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: "1%", whiteSpace: "nowrap" }}
            >
              <Typography variant="body2" sx={{ display: "block" }}>
                {format(
                  new Date(item.valid_start.replace(/-/g, "/")),
                  "HH:mm - dd/MMM/yyyy"
                )}
              </Typography>
              <Typography variant="body2">
                {format(
                  new Date(item.valid_end.replace(/-/g, "/")),
                  "HH:mm - dd/MMM/yyyy"
                )}
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: "70px", whiteSpace: "nowrap" }}
            >
              <Grid container gap={1} alignItems="center">
                <Button
                  variant="contained"
                  color="warning"
                  disabled={
                    this.props.permissions.indexOf("edit coupon") !== -1
                      ? false
                      : true
                  }
                  onClick={(e) => {
                    this.props.history.push(url + "/edit/" + item.code);
                  }}
                >
                  Edit
                </Button>
              </Grid>
            </TableCell>
          </TableRow>
        );
      });
    }

    return (
      <Container className="main-panel booth">
        <Grid container className="header-panel" alignItems="center" mb={1}>
          <Grid item md={6} xs={6} sx={{ justifyContent: "flex-start" }}>
            <Typography variant="h6">
              {!this.props.title ? "" : this.props.title}
            </Typography>
          </Grid>
          <Grid item md={6} xs={6} sx={{ textAlign: "right" }}>
            {dataExport.length !== 0 ? (
              <CSVLink
                className="btn-export-report warning"
                filename={"voucher-raw-data.csv"}
                data={dataExport}
                separator={";"}
                headers={[
                  { label: "No", key: "no" },
                  { label: "Code", key: "code" },
                  { label: "Booth", key: "applied_booth.name" },
                  { label: "Quantity", key: "qty" },
                  { label: "Description", key: "description" },
                  { label: "Expired In", key: "expired_at" },
                  { label: "Validity", key: "valid_date" },
                ]}
              >
                DOWNLOAD RAW
              </CSVLink>
            ) : (
              ""
            )}
            {this.props.permissions.indexOf("create coupon") !== -1 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  this.props.history.push(url + "/add");
                }}
              >
                Add Voucher
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <Grid
          container
          className="header-panel"
          alignItems="center"
          sx={{
            paddingTop: "10px",
            paddingBottom: "10px",
            marginBottom: "10px",
          }}
        >
          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="select-user-label">User</InputLabel>
              <Select
                size="small"
                label="User"
                name="filter_iduser"
                id="select-user"
                labelId="select-user-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_iduser}
              >
                <MenuItem value={0} key={-1}>
                  All
                </MenuItem>
                {this.state.users.map((item) => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.fullname || item.username}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="select-category-label">Categories</InputLabel>
              <Select
                size="small"
                label="Categories"
                name="filter_category"
                id="select-category"
                labelId="select-category-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_category}
              >
                <MenuItem value={0} key={-1}>
                  All
                </MenuItem>
                {this.state.categories.map((item) => {
                  return (
                    <MenuItem value={item} key={item}>
                      {capitalize(item)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="select-type-label">Type</InputLabel>
              <Select
                size="small"
                label="Type"
                name="filter_type"
                id="select-type"
                labelId="select-type-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_type}
              >
                <MenuItem value={0} key={-1}>
                  All
                </MenuItem>
                {this.state.type.map((item) => {
                  return (
                    <MenuItem value={item} key={item} sx={{textTransform: "uppercase"}}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <TextField
              size="small"
              id="input-code"
              name="filter_code"
              label="Voucher Code"
              placeholder="xbdobintangxxx"
              // sx={{height:"8px"}}
              onChange={this.handleChangeFilter}
              value={this.state.filter_code}
            ></TextField>
            <TextField
              size="small"
              id="input-description"
              name="filter_description"
              label="Description"
              placeholder="Event Jakarta"
              // sx={{height:"8px"}}
              onChange={this.handleChangeFilter}
              value={this.state.filter_description}
            ></TextField>
            <FormControl>
              <InputLabel id="select-perpage-label">Per Page</InputLabel>
              <Select
                size="small"
                label="Perpage"
                name="filter_perpage"
                id="select-perpage"
                labelId="select-perpage-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_perpage}
                sx={{ width: "100px" }}
              >
                {/* <MenuItem value={0} key={-1}>25</MenuItem> */}
                {this.state.perpage.map((item, idx) => {
                  return (
                    <MenuItem value={idx} key={idx}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-perpage-label">Order By</InputLabel>
              <Select
                size="small"
                label="Order By"
                name="filter_order"
                id="select-order"
                labelId="select-order-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_order}
                sx={{ width: "100px" }}
              >
                {this.state.orderBy.map((item, idx) => {
                  return (
                    <MenuItem value={item.id} key={idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-perpage-label">Sort By</InputLabel>
              <Select
                size="small"
                label="Sort By"
                name="filter_sort"
                id="select-sort"
                labelId="select-sort-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_sort}
                sx={{ width: "100px" }}
              >
                {this.state.sortBy.map((item, idx) => {
                  return (
                    <MenuItem value={item.id} key={idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {!this.state.is_loading ? (
              <Button
                ref={(button) => (this.buttonElement = button)}
                variant="contained"
                onClick={this.handleFilterSubmit}
              >
                Filter
              </Button>
            ) : (
              <Typography className="btn-loading-filter">
                HARAP SABAR...
              </Typography>
            )}
            <Button variant="contained" onClick={this.handleFilterClear}>
              Clear Filter
            </Button>
            {/* <Button variant='contained' sx={{marginRight:"5px"}} onClick={this.handleFilterSubmit}>Filter</Button> */}
          </Grid>
        </Grid>
        <Paper>
          <TableContainer sx={{ maxHeight: 570 }}>
            <InfiniteScroll
              // id="infinite-scroll-component"
              height={500}
              dataLength={this.state.data.length}
              next={this.loadFunc}
              hasMore={this.state.hasMore}
              loader={
                <Typography
                  id="load-data"
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    padding: "15px",
                  }}
                >
                  Loading...
                </Typography>
              }
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell align="center">Code</TableCell>
                    <TableCell align="center">Booth</TableCell>
                    <TableCell align="center">Type</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="center">Expired In</TableCell>
                    <TableCell align="center">Validity</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tableBody}</TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>

          {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={10}
                        rowsPerPage={15}
                        page={10}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
        </Paper>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getUserAll: (data) => dispatch(getUserAll(data)),
    getCouponAll: (data) => dispatch(getCouponAll(data)),
    getCouponSearch: (data) => dispatch(getCouponSearch(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(CouponAll))
);
