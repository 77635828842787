import axios from "axios";
import { BASE_URL, BASE_VERSION } from "../config";

export const addSales = (payload) => {
  return (dispatch) => {
    return axios
      .post(BASE_URL + BASE_VERSION + "signin", {
        email: payload.email,
        password: payload.password,
      })
      .then(function (response) {
        payload = response.data;
        if (payload.code === 200) {
          dispatch({ type: "SET_LOGIN", payload });
        }
        return payload;
      })
      .catch(function (error) {
        console.log(error);
        payload = error;
        dispatch({ type: "SET_LOGOUT", payload });
        return payload;
      });
  };
};

export const getSales = (payload) => {
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "transaction/all", {
        params: payload,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};

export const getSalesSearch = (payload) => {
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "transaction/search", {
        params: payload,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};

export const getSalesDetail = (payload) => {
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "transaction/" + payload.orderid, {
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};

export const setRefund = (payload) => {
  return (dispatch) => {
    return axios
      .put(
        BASE_URL + BASE_VERSION + "transaction/refund/" + payload.orderid,
        null,
        {
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
          },
        }
      )
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      });
  };
};
// transaction/refund/:idorder put
