import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login } from "./redux/actions/users";

// import { createTheme, ThemeProvider } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';

import Header from "./components/header";
import Footer from "./components/footer";

// import Home from "./pages/home";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";

import {
  BrowserRouter as Router,
	Route,
	Switch,
  Redirect
} from "react-router-dom";

import './App.css';
import './AppMobile.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      err:null
    };
  }

  componentDidUpdate(prevProps) {
    // console.log(this.props.isLogin);
    if (this.props.isLogin !== this.state.isLogin) {
      this.setState({isLogin:this.props.isLogin})
    }
  }

  render() {
    const theme = createTheme({
      components: {
        MuiTypography: {
          defaultProps: {
            variantMapping: {
              h1: 'h2',
              h2: 'h2',
              h3: 'h2',
              h4: 'h2',
              h5: 'h2',
              h6: 'h2',
              subtitle1: 'h2',
              subtitle2: 'h2',
              body1: 'span',
              body2: 'span',
            },
          },
        },
      },
    });
    // const theme = createTheme();

    return (
      <ThemeProvider theme={theme}>
      <Box>        
      {/* <ThemeProvider theme={theme}></ThemeProvider> */}
        <Router basename="/">
          <Header />
          <Box className='body body-container'>
            <Switch>
              <PrivateRoute loginState={this.state.isLogin} path="/dashboard">
                <Dashboard />
              </PrivateRoute>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route path="/">
                {/* <Home /> */}
                <Redirect to="/dashboard"></Redirect>
              </Route>
            </Switch>
          </Box>
          <Footer />
        </Router>
      {/* </ThemeProvider> */}
      </Box>
      </ThemeProvider>
    );
  }
}

function PrivateRoute({ children, ...rest }) {
  return (    
    <Route
      {...rest}
      render={({ location }) =>
        (rest.loginState !== false) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = state => ({
  ...state
});

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    login
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(App);