import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Redirect,
    withRouter
} from "react-router-dom";

import { login, checkLogin } from "../redux/actions/users";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin:false,
            from:'/dashboard',
            err:null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        this.props.checkLogin().then((res) => {
            if(res.code === 200){
                this.setState({isLogin:true, from:this.props.location?.state?.from?.pathname});
            }else{
                this.setState({isLogin:false});
            }
        });
    }

    handleSubmit(event){
        event.preventDefault();

        let ctx = this;
        ctx.setState({err:null});

        const data = new FormData(event.currentTarget);
        this.props.login({
            email: data.get('email'),
            password: data.get('password'),
        }).then(function (data){
            if(data.code !== 200){
                ctx.setState({err: data.message});
            }else{
                ctx.setState({isLogin:true});
            }
        }).catch(function (e) {
            ctx.setState({err:"Something error"});
        });
    }

    render() {
        let from = this.state.from;
        if(from === undefined){
            from = "/dashboard"
        }

        return (
            <Box className='login-page'>
                {(this.state.isLogin !== true)?"":<Redirect to={from}></Redirect>}            
                <Box className='login-container'>
                    <Typography className='login-title' sx={{textAlign:"center"}}>Login</Typography>
                    <Box component="form" onSubmit={this.handleSubmit} noValidate>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        {/* <Typography className="text-error-info">{this.state.err}</Typography> */}
                        <Button fullWidth type="submit" variant="contained">Sign In</Button>
                    </Box>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        login: data => dispatch(login(data)),
        checkLogin: data => dispatch(checkLogin(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));