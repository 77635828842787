import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Container,
  Stack,
  Grid,
  Typography,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from "@mui/material";

import { getCategoryAll, deleteCategory } from "../redux/actions/categories";

class Categories extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies),
  };
  constructor(props) {
    super(props);
    this.state = {
      filter_perpage: 0,
      filter_order: "",
      filter_sort: "",
      filter_name: "",
      is_loading: false,
      perpage: [30, 50, 100, 250, 500, 1000],
      orderBy: [
        { id: "name", name: "Name" },
        { id: "is_active", name: "Status" },
      ],
      sortBy: [
        { id: "ASC", name: "Ascending" },
        { id: "DESC", name: "Descending" },
      ],
      data: [],
      err: null,
      is_loading: false,
      page: 1,
      hasMore: false,
    };
  }

  componentDidMount() {
    this.loadData();
    this.fetchCookies();
  }

  params = () => {
    let params = {};

    if (this.state.filter_name !== "") {
      params.name = this.state.filter_name;
    }
    if (this.state.filter_order !== "") {
      params.order = this.state.filter_order;
    }
    if (this.state.filter_sort !== "") {
      params.sort = this.state.filter_sort;
    }

    params.perpage = this.state.perpage[this.state.filter_perpage];
    params.page = 1;

    return params;
  };

  loadData = async () => {
    let params = this.params();
    const category = await this.props.getCategoryAll(params);
    if (category.code === 200) {
      this.setState({
        data: category.data,
        hasMore:
          this.state.perpage[this.state.filter_perpage] !== category.data.length
            ? false
            : true,
        is_loading: false,
      });
    } else {
      this.setState({
        data: [],
        hasMore: false,
        is_loading: false,
      });
    }
  };

  handleChangeFilter = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFilterSubmit = (e) => {
    this.setState({ is_loading: true });
    e.preventDefault();

    const { cookies } = this.props;
    let expires = new Date();
    let data = {
      filter_perpage: this.state.filter_perpage,
      filter_order: this.state.filter_order,
      filter_sort: this.state.filter_sort,
      filter_name: this.state.filter_name,
    };
    expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);

    cookies.set("category_cookies", data, { path: "/", expires: expires });

    this.loadData();
  };

  handleFilterClear = (e) => {
    this.setState(
      {
        filter_perpage: 0,
        filter_order: "",
        filter_sort: "",
        filter_name: "",
      },
      async function () {
        await this.props.cookies.remove("category_cookies", {
          path: "/",
          expires: 0,
        });
        await this.loadData();
      }
    );
  };

  fetchCookies = () => {
    var category_cookies = this.props.cookies.get("category_cookies");
    if (category_cookies !== undefined) {
      this.setState(
        {
          filter_perpage: category_cookies.filter_perpage,
          filter_order: category_cookies.filter_order,
          filter_sort: category_cookies.filter_sort,
          filter_name: category_cookies.filter_name,
        },
        () => {
          this.buttonElement.click();
        }
      );
    }
  };

  componentDidUpdate(prevProv, prevState) {
    if (prevProv.cookies !== this.props.cookies) {
      this.fetchCookies();
    }
  }

  loadFunc = () => {
    const ctx = this;
    let params = this.params();
    params.page = this.state.page + 1;
    this.props
      .getCategoryAll(params)
      .then((e) => {
        if (e.code === 200) {
          if (e.data !== null) {
            setTimeout(() => {
              ctx.setState({
                data: this.state.data.concat(e.data),
                hasMore:
                  e.data.length !==
                  this.state.perpage[this.state.filter_perpage]
                    ? false
                    : true,
                page: this.state.page + 1,
              });
            }, 1500);
          } else {
            ctx.setState({ hasMore: false, page: this.state.page + 1 });
          }
        } else {
          ctx.setState({
            err: e.message,
            hasMore: false,
            page: this.state.page + 1,
          });
        }
      })
      .catch((e) => {
        alert("Something Error");
      });
  };

  handleDelete = (e) => {
    const ctx = this;
    e.preventDefault();
    var confirmed = window.confirm("Are you sure to delete this category?");
    if (confirmed) {
      ctx.props
        .deleteCategory({ slug: e.target.dataset.slug })
        .then((e) => {
          if (e.code === 200) {
            // this.loadData();
            window.location.reload();
            alert("Delete Success");
          } else {
            alert("Something Error : " + e.message);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Something Error : " + e.message);
        });
    }
  };

  render() {
    const url = this.props.match.url;

    return (
      <Container className="main-panel booth">
        <Grid container className="header-panel" alignItems="center" mb={1}>
          <Grid item md={6} xs={6} sx={{ justifyContent: "flex-start" }}>
            <Typography variant="h6">
              {!this.props.title ? "" : this.props.title}
            </Typography>
          </Grid>
          <Grid item md={6} xs={6} sx={{ textAlign: "right" }}>
            <Stack direction={"row"} spacing={2} justifyContent="flex-end">
              {this.props.permissions.indexOf("create frame") !== -1 ? (
                <Button
                  alt="Test Experimental"
                  variant="contained"
                  color="primary"
                  sx={{ marginRight: "10px" }}
                  onClick={(e) => {
                    this.props.history.push(url + "/add");
                  }}
                >
                  Add Category
                </Button>
              ) : null}
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          className="header-panel"
          alignItems="center"
          sx={{
            paddingTop: "10px",
            paddingBottom: "10px",
            marginBottom: "10px",
          }}
        >
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              display: "flex",
              gap: "5px",
              justifyContent: "flex-start",
              flexWrap: { xs: "wrap", md: "nowrap" },
            }}
          >
            <TextField
              size="small"
              id="input-name"
              name="filter_name"
              label="Name"
              placeholder="Filter Name"
              onChange={this.handleChangeFilter}
              value={this.state.filter_name}
            ></TextField>
            <FormControl>
              <InputLabel id="select-perpage-label">Per Page</InputLabel>
              <Select
                size="small"
                label="Perpage"
                name="filter_perpage"
                id="select-perpage"
                labelId="select-perpage-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_perpage}
                sx={{ width: "100px" }}
              >
                {this.state.perpage.map((item, idx) => {
                  return (
                    <MenuItem value={idx} key={idx}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-perpage-label">Order By</InputLabel>
              <Select
                size="small"
                label="Order By"
                name="filter_order"
                id="select-order"
                labelId="select-order-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_order}
                sx={{ width: "100px" }}
              >
                {this.state.orderBy.map((item, idx) => {
                  return (
                    <MenuItem value={item.id} key={idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-perpage-label">Sort By</InputLabel>
              <Select
                size="small"
                label="Sort By"
                name="filter_sort"
                id="select-sort"
                labelId="select-sort-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_sort}
                sx={{ width: "100px" }}
              >
                {this.state.sortBy.map((item, idx) => {
                  return (
                    <MenuItem value={item.id} key={idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {!this.state.is_loading ? (
              <Button
                ref={(button) => (this.buttonElement = button)}
                variant="contained"
                onClick={this.handleFilterSubmit}
              >
                Filter
              </Button>
            ) : (
              <Typography className="btn-loading-filter">
                HARAP SABAR...
              </Typography>
            )}
            <Button variant="contained" onClick={this.handleFilterClear}>
              Clear Filter
            </Button>
          </Grid>
        </Grid>
        <Paper>
          <TableContainer sx={{ maxHeight: 570 }}>
            <InfiniteScroll
              // id="infinite-scroll-component"
              height={500}
              dataLength={this.state.data.length}
              next={this.loadFunc}
              hasMore={this.state.hasMore}
              loader={
                <Typography
                  id="load-data"
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    padding: "15px",
                  }}
                >
                  Loading...
                </Typography>
              }
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell align="center">Icon</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Slug</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.data.map((item, idx) => {
                    if (item.id === 1) {
                      return false;
                    }
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                        <TableCell sx={{ width: "1%", whiteSpace: "nowrap" }}>
                          {(idx += 1)}
                        </TableCell>
                        <TableCell align="center">
                          {item.fullurl_icon ? (
                            <Box
                              sx={{
                                background: "url(" + item.fullurl_icon + ")",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                height: 50,
                                width: 50,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                cursor: "pointer",
                                margin: "0 auto",
                              }}
                            ></Box>
                          ) : null}
                        </TableCell>
                        <TableCell align="left">{item.name}</TableCell>
                        <TableCell align="left">{item.slug}</TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "20%", whiteSpace: "nowrap" }}
                        >
                          {item.is_active === true ? "Active" : "Not Active"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "70px", whiteSpace: "nowrap" }}
                        >
                          <Stack direction={"row"} spacing={2}>
                            <Button
                              variant="contained"
                              color="warning"
                              disabled={
                                this.props.permissions.indexOf("edit frame") !==
                                -1
                                  ? false
                                  : true
                              }
                              onClick={(e) => {
                                this.props.history.push(
                                  url + "/edit/" + item.slug
                                );
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              data-slug={item.slug}
                              disabled={
                                this.props.permissions.indexOf(
                                  "delete frame"
                                ) !== -1
                                  ? false
                                  : true
                              }
                              onClick={this.handleDelete}
                            >
                              Delete
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </Paper>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCategoryAll: (data) => dispatch(getCategoryAll(data)),
    deleteCategory: (data) => dispatch(deleteCategory(data)),
    // getFrameAll: (data) => dispatch(getFrameAll(data)),
    // deleteFrame: (data) => dispatch(deleteFrame(data)),
    // getFrameSearch: (data) => dispatch(getFrameSearch(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(Categories))
);
