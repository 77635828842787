import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Link,
    withRouter
} from "react-router-dom";

import { logout } from "../redux/actions/users";
import { toggleMenuON, toggleMenuOFF } from "../redux/actions/frontend";


// import { Box, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
// import Typography from '@mui/material/Typography';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileMenu: false,
            err:null
        };

        this.toggleMenu = this.toggleMenu.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    toggleMenu(){
        if(this.props.menuOn === false){
            this.props.toggleMenuON()
        }else{
            this.props.toggleMenuOFF()            
        }
    }

    handleLogout(e){
        e.preventDefault();
        this.props.logout().then((res) => {
            console.log(res);
        }).catch((err)=>{
            console.log(err);
        })
    }

    // componentDidUpdate(){
    //     console.log(this.props.menuOn);
    // }

    render() {
        return (
            <Box className='main-header'>
                <Box className='logo-wrapper'>
                    <Link to='/home'>Photograms</Link>
                </Box>
                {this.props.isLogin?
                <Box className='mobile-menu-wrapper'>
                    <Button variant="container" color="danger" onClick={this.handleLogout}>Logout</Button>
                    <Button variant="container" onClick={this.toggleMenu}>Menu</Button>
                </Box>:""}
                <Box className='nav-link'>
                    <ul>
                        <li>{this.props.isLogin===true?<Link to='#' onClick={this.handleLogout}>Logout</Link>:<Link to='/login'>Login</Link>} </li>
                    </ul>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: userData => dispatch(logout(userData)),
        toggleMenuON: userData => dispatch(toggleMenuON(userData)),
        toggleMenuOFF: userData => dispatch(toggleMenuOFF(userData))
    };
}

function mapStateToProps(state){
    return{
        isLogin : state.isLogin,
        userData : state.userData,
        menuOn: state.menuOn
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));