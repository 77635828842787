import React, { Component } from "react";
import { connect } from "react-redux";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { format } from "date-fns";

import { CSVLink } from "react-csv"; //CSVDownload

import {
  getSales,
  getSalesSearch,
  getSalesDetail,
  setRefund,
} from "../redux/actions/sales";
import { getBrandAll } from "../redux/actions/brands";
import { getBoothAll, getBoothSearch } from "../redux/actions/booths";
import { getFrameAll } from "../redux/actions/frames";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import {
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Modal,
  Stack,
  Box,
  Autocomplete,
} from "@mui/material";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

class SalesAll extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies),
  };
  constructor(props) {
    super(props);
    this.state = {
      filter_idbrand: 0,
      filter_idbooth: 0,
      filter_namebooth: "",
      filter_nameframe: "",
      filter_status: 0,
      filter_idorder: "",
      filter_description: "",
      filter_startdate: null,
      filter_enddate: null,
      filter_perpage: 0,
      filter_payment: "",
      filter_order: "",
      filter_sort: "",
      perpage: [30, 50, 100, 250, 500, 1000, 2500, 3000],
      brand: [],
      booth: [],
      status: ["All", "COMPLETED", "PENDING", "REFUND"],
      orderBy: [
        { id: "created_at", name: "Tanggal" },
        { id: "idbrand", name: "Brand" },
        { id: "idbooth", name: "Booth" },
        { id: "status", name: "Status" },
        { id: "idorder", name: "ID Order" },
      ],
      sortBy: [
        { id: "ASC", name: "Ascending" },
        { id: "DESC", name: "Descending" },
      ],
      data: [],
      detail: null,
      data_cleaned: null,
      data_perday: null,
      err: null,
      is_loading: false,
      page: 1,
      hasMore: true,
      modal: false,
      headers: [
        { label: "No", key: "no" },
        { label: "ID", key: "id" },
        { label: "IDOrder", key: "idorder" },
        { label: "Status", key: "status" },
        { label: "Settled At", key: "settledAt" },
        { label: "Source", key: "source" },
        { label: "IDReceipt", key: "receipt_id" },
        { label: "Description", key: "description" },
        { label: "Price", key: "price" },
        { label: "Sub Total", key: "subtotal" },
        { label: "Total", key: "total" },
        { label: "Created At", key: "created_at" },
        { label: "IDPhoto", key: "idphoto" },
        { label: "IDPayment", key: "idpayment" },
        { label: "Payment", key: "payment.name" },
        { label: "IDBooth", key: "idbooth" },
        { label: "IDFrame", key: "idframe" },
      ],
      // dateWithNoInitialValue:
    };

    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.generateDataCSV = this.generateDataCSV.bind(this);
  }

  handleChangeStartDate = (event, e) => {
    this.setState({
      filter_startdate: event,
    });
  };

  handleChangeEndDate = (event, e) => {
    this.setState({
      filter_enddate: event,
    });
  };

  generateDataCSV(data) {
    let dataTmp = data;
    let data_cleanedtmp = [];
    let data_perdaytmp = [];

    if (dataTmp !== null) {
      for (let i = 0; i < dataTmp.length; i++) {
        delete dataTmp[i].signaturekey;
        delete dataTmp[i].qrcode;
        delete dataTmp[i].qrcode_id;
        delete dataTmp[i].vacode;
        delete dataTmp[i].quantity;
        delete dataTmp[i].paymentfee;
        delete dataTmp[i].tax;
        delete dataTmp[i].discount;
        delete dataTmp[i].is_active;
        delete dataTmp[i].updated_at;
        delete dataTmp[i].iduser;

        dataTmp[i] = { ...{ no: i + 1 }, ...dataTmp[i] };
        data_cleanedtmp.push(dataTmp[i]);

        let total = dataTmp[i].total;
        let date = dataTmp[i].created_at.split(" ")[0].replace(/-/g, "-");
        let indexPerday = data_perdaytmp.findIndex((x) => x.date === date);

        if (indexPerday === -1) {
          data_perdaytmp.push({
            date: date,
            total: total,
            total_format: "IDR" + total.toLocaleString("id"),
          });
        } else {
          data_perdaytmp[indexPerday].total += dataTmp[i].total;
          data_perdaytmp[indexPerday].total_format =
            "IDR" + data_perdaytmp[indexPerday].total.toLocaleString("id");
        }
      }
      this.setState({
        data_cleaned: data_cleanedtmp,
        data_perday: data_perdaytmp,
      });
    } else {
      this.setState({
        data_cleaned: null,
        data_perday: null,
        data: null,
      });
    }
  }

  handleChangeFilter = async (e) => {
    if (e.target.name === "filter_idbrand") {
      if (e.target.value !== 0) {
        const booth = await this.props.getBoothAll({ idbrand: e.target.value });
        if (booth.code === 200) {
          this.setState({ booth: booth.data });
        }
      } else {
        const booth = await this.props.getBoothAll();
        if (booth.code === 200) {
          this.setState({ booth: booth.data });
        }
      }
    }

    this.setState({ [e.target.name]: e.target.value });
  };

  params = () => {
    let params = {};
    if (this.state.filter_idbooth !== 0) {
      params.idbooth = this.state.filter_idbooth;
    }
    if (this.state.filter_nameframe !== "") {
      params["$frame.name$"] = this.state.filter_nameframe;
    }
    if (this.state.filter_status !== 0) {
      params.status = this.state.status[this.state.filter_status];
    }
    if (this.state.filter_idorder !== "") {
      params.idorder = this.state.filter_idorder;
    }
    if (this.state.filter_description !== "") {
      params.description = this.state.filter_description;
    }
    if (
      this.state.filter_startdate !== "" &&
      this.state.filter_startdate !== null
    ) {
      params.start_date = this.state.filter_startdate;
    }
    if (
      this.state.filter_enddate !== "" &&
      this.state.filter_enddate !== null
    ) {
      params.end_date = this.state.filter_enddate;
    }
    if (this.state.filter_order !== "") {
      params.order = this.state.filter_order;
    }
    if (this.state.filter_sort !== "") {
      params.sort = this.state.filter_sort;
    }
    if (this.state.filter_payment !== "") {
      params.idpayment = this.state.filter_payment;
    }
    params.perpage = this.state.perpage[this.state.filter_perpage];
    params.page = 1;

    return params;
  };

  handleFilterSubmit(e) {
    this.setState({ is_loading: true });
    e.preventDefault();

    let params = this.params();

    const { cookies } = this.props;
    let expires = new Date();
    let data = {
      filter_idbrand: this.state.filter_idbrand,
      filter_idbooth: this.state.filter_idbooth,
      filter_namebooth: this.state.filter_namebooth,
      filter_nameframe: this.state.filter_nameframe,
      filter_status: this.state.filter_status,
      filter_idorder: this.state.filter_idorder,
      filter_description: this.state.filter_description,
      filter_startdate: this.state.filter_startdate,
      filter_enddate: this.state.filter_enddate,
      filter_perpage: this.state.filter_perpage,
      filter_order: this.state.filter_order,
      filter_sort: this.state.filter_sort,
      filter_payment: this.state.filter_payment,
    };
    expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);

    cookies.set("sales_cookies", data, { path: "/", expires: expires });

    this.props.getSales(params).then((e) => {
      if (e.code === 200) {
        if (e.data !== null) {
          this.setState({
            data: e.data,
            hasMore: e.data.length !== params.perpage ? false : true,
            is_loading: false,
            page: 1,
          });
        } else {
          this.setState({
            data: [],
            hasMore: false,
            is_loading: false,
            page: 1,
          });
        }
      } else {
        this.setState({ err: e.message, is_loading: false, page: 1 });
      }
    });
  }

  handleOpen = (value, elm) => {
    const ctx = this;
    ctx.props.getSalesDetail({ orderid: value }).then((response) => {
      if (response.code === 200) {
        ctx.setState({
          modal: true,
          detail: response.data,
        });
      }
    });
  };

  handleClose = () => {
    this.setState({
      modal: false,
      detail: null,
    });
  };

  componentDidMount() {
    var ctx = this;
    this.props.getBrandAll().then((e) => {
      if (e.code === 200) {
        this.setState({ brand: e.data });
      }
    });
    this.props.getBoothAll().then((e) => {
      if (e.code === 200) {
        this.setState({ booth: e.data });
      }
    });

    this.props
      .getSales({
        perpage: this.state.perpage[this.state.filter_perpage],
        page: this.state.page,
      })
      .then(async (e) => {
        if (e.code === 200) {
          if (e.data !== null) {
            ctx.setState({
              data: e.data,
              hasMore: e?.last_page !== e?.page ? true : false,
            });
          } else {
            ctx.setState({ data: [], hasMore: false });
          }
          this.generateDataCSV(e.data);
        } else {
          ctx.setState({ err: e.message });
        }
        await this.fetchCookies();
      })
      .catch((e) => {
        alert("Something Error");
      });
  }

  componentDidUpdate(prevProv, prevState) {
    if (prevProv.cookies !== this.props.cookies) {
      this.fetchCookies();
    }
  }

  fetchCookies = () => {
    var sales_cookies = this.props.cookies.get("sales_cookies");
    if (sales_cookies !== undefined) {
      this.setState(
        {
          filter_idbrand: sales_cookies.filter_idbrand,
          filter_idbooth: sales_cookies.filter_idbooth,
          filter_namebooth: sales_cookies.filter_namebooth,
          filter_nameframe: sales_cookies.filter_nameframe,
          filter_status: sales_cookies.filter_status,
          filter_idorder: sales_cookies.filter_idorder,
          filter_description: sales_cookies.filter_description,
          filter_startdate: sales_cookies.filter_startdate,
          filter_enddate: sales_cookies.filter_enddate,
          filter_perpage: sales_cookies.filter_perpage,
          filter_order: sales_cookies.filter_order,
          filter_sort: sales_cookies.filter_sort,
          filter_payment: sales_cookies.filter_payment,
        },
        () => {
          this.buttonElement.click();
        }
      );
    }
  };

  handleFilterClear = (e) => {
    this.setState(
      {
        filter_idbrand: 0,
        filter_idbooth: 0,
        filter_namebooth: "",
        filter_nameframe: "",
        filter_status: 0,
        filter_idorder: "",
        filter_description: "",
        filter_startdate: null,
        filter_enddate: null,
        filter_perpage: 0,
        filter_order: "",
        filter_sort: "",
        filter_payment: "",
      },
      () => {
        this.props.cookies.remove("sales_cookies", { path: "/", expires: 0 });
        this.props
          .getSales({
            perpage: this.state.perpage[this.state.filter_perpage],
            page: this.state.page,
          })
          .then(async (e) => {
            if (e.code === 200) {
              if (e.data !== null) {
                this.setState({ data: e.data });
              } else {
                this.setState({ data: [], hasMore: false });
              }
              this.generateDataCSV(e.data);
            } else {
              this.setState({ err: e.message });
            }
            await this.fetchCookies();
          })
          .catch((e) => {
            alert("Something Error");
          });
      }
    );
  };

  loadFunc = () => {
    let params = this.params();
    params.page = this.state.page + 1;
    this.props
      .getSales(params)
      .then((e) => {
        if (e.code === 200) {
          if (e.data !== null) {
            setTimeout(() => {
              this.setState({
                data: this.state.data.concat(e.data),
                hasMore:
                  e.data.length !==
                  this.state.perpage[this.state.filter_perpage]
                    ? false
                    : true,
                is_loading: false,
                page: params.page,
              });
            }, 1500);
          } else {
            this.setState({
              hasMore: false,
              is_loading: false,
              page: params.page,
            });
          }
        } else {
          this.setState({ err: e.message, is_loading: false, page: 1 });
        }
      })
      .catch((e) => {
        alert("Something Error");
      });
  };

  statusCek = (value, elm) => {
    const ctx = this;
    ctx.props.getSalesDetail({ orderid: value }).then((response) => {
      if (response.code === 200) {
        if (response.data.status !== ctx.state.detail.status) {
          alert("ada perubahan status");
          ctx.setState(
            {
              detail: response.data,
            },
            function () {
              window.location.reload();
            }
          );
        } else {
          alert("tidak ada perubahan status");
        }
      }
    });
  };

  setRefund = (value, elm) => {
    const answer = window.confirm("Are you sure?");
    const ctx = this;
    if (answer) {
      ctx.props
        .setRefund({ orderid: value })
        .then((response) => {
          if (response.code === 200) {
            alert(response.message);
            window.location.reload(true);
          } else {
            alert(response.message);
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  render() {
    console.log("frmae: ", this.state.frame);
    var tableBody;
    if (this.state.data !== null) {
      tableBody = this.state.data.map((item, idx) => {
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
            <TableCell sx={{ width: "1%", whiteSpace: "nowrap" }}>
              {(idx += 1)}
            </TableCell>
            <TableCell align="left">
              <Typography
                variant="body2"
                sx={{
                  display: "block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.idorder}
              </Typography>
            </TableCell>
            <TableCell align="left">
              IDR{item.total.toLocaleString("id")}
            </TableCell>
            <TableCell align="center">
              {item.frame?.name || "Tidak ada Frame"}
            </TableCell>
            <TableCell align="center">
              {item.payment?.name || "Tidak ada Pembayaran"}
            </TableCell>
            <TableCell>{item.description}</TableCell>
            <TableCell>
              <span className="field-time">
                {format(
                  new Date(item.created_at.replace(/-/g, "/")),
                  "yyyy-MM-dd HH:mm"
                )}
              </span>
              <span className="field-time">
                {item.settledAt !== null
                  ? format(
                      new Date(item.settledAt.replace(/-/g, "/")),
                      "yyyy-MM-dd HH:mm"
                    )
                  : "No Trans"}
              </span>
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: "1%", whiteSpace: "nowrap" }}
            >
              {item.status}
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: "70px", whiteSpace: "nowrap" }}
            >
              <Grid container gap={1} alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleOpen.bind(this, item.idorder)}
                >
                  Detail
                </Button>
              </Grid>
            </TableCell>
          </TableRow>
        );
      });
    }

    var refund;
    if (this.props.permissions.indexOf("set refund") !== -1) {
      if (this.state.detail !== null) {
        if (this.state.detail.status === "COMPLETED") {
          refund = (
            <Button
              variant="contained"
              color="warning"
              onClick={this.setRefund.bind(
                this,
                this.state.detail?.idorder || null
              )}
            >
              Refund
            </Button>
          );
        }
      }
    }
    return (
      <Container className="main-panel booth">
        <Grid container className="header-panel" alignItems="center" mb={1}>
          <Grid item md={6} sx={{ justifyContent: "flex-start" }}>
            <Typography variant="h6">
              {!this.props.title ? "" : this.props.title}
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            sx={{ textAlign: "right" }}
            className="container-btn-export-report"
          >
            {this.state.data_perday !== null ? (
              <CSVLink
                className="btn-export-report"
                filename={"report-raw-perday.csv"}
                data={this.state.data_perday}
                separator={";"}
              >
                DOWNLOAD PER DAY
              </CSVLink>
            ) : (
              ""
            )}
            {this.state.data_cleaned !== null ? (
              <CSVLink
                className="btn-export-report"
                filename={"report-cleaned-data.csv"}
                data={this.state.data_cleaned}
                separator={";"}
                headers={this.state.headers}
              >
                DOWNLOAD DATA
              </CSVLink>
            ) : (
              ""
            )}
            {this.state.data !== null ? (
              <CSVLink
                className="btn-export-report warning"
                filename={"report-raw-data.csv"}
                data={this.state.data}
                separator={";"}
                headers={this.state.headers}
              >
                DOWNLOAD RAW
              </CSVLink>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid
          container
          className="header-panel"
          alignItems="center"
          sx={{
            paddingTop: "10px",
            paddingBottom: "10px",
            marginBottom: "10px",
          }}
        >
          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <FormControl sx={{ minWidth: 120, maxHeight: 140 }}>
              <InputLabel id="select-brand-label">Brand</InputLabel>
              <Select
                size="small"
                label="Brand"
                name="filter_idbrand"
                id="select-brand"
                labelId="select-brand-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_idbrand}
                sx={{ marginBottom: { md: "10px" } }}
              >
                <MenuItem value={0} key={-1}>
                  All
                </MenuItem>
                {this.state.brand.map((item) => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Autocomplete
              freeSolo
              name="filter_idbooth"
              id="free-solo-2-demo"
              size="small"
              // disableClearable
              onChange={(e, v) => {
                this.setState({
                  filter_idbooth: v.id,
                  filter_namebooth: v.label,
                });
              }}
              value={this.state.filter_namebooth || ""}
              options={this.state.booth.map((option) => ({
                id: option.id,
                label: option.name,
              }))}
              sx={{ minWidth: 200 }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Booth"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                );
              }}
            />
            <TextField
              size="small"
              id="input-filter_nameframe"
              name="filter_nameframe"
              label="Frame"
              placeholder="Frame"
              onChange={this.handleChangeFilter}
              value={this.state.filter_nameframe}
            ></TextField>
            <TextField
              size="small"
              id="input-filter_idorder"
              name="filter_idorder"
              label="ID Order"
              placeholder="ID Order"
              onChange={this.handleChangeFilter}
              value={this.state.filter_idorder}
            ></TextField>
            <TextField
              size="small"
              id="input-filter_description"
              name="filter_description"
              label="Description"
              placeholder="Description"
              onChange={this.handleChangeFilter}
              value={this.state.filter_description}
            ></TextField>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                inputFormat="yyyy-MM-dd HH:mm"
                ampm={false}
                label="Date Start"
                name="filter_startdate"
                type="date"
                value={this.state.filter_startdate}
                onChange={this.handleChangeStartDate}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "195px" }}
                    size="small"
                    {...params}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                inputFormat="yyyy-MM-dd HH:mm"
                ampm={false}
                label="Date End"
                name="filter_enddate"
                type="date"
                value={this.state.filter_enddate}
                onChange={this.handleChangeEndDate}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "195px" }}
                    size="small"
                    {...params}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="select-status-label">Status</InputLabel>
              <Select
                size="small"
                label="Status"
                name="filter_status"
                id="select-status"
                labelId="select-status-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_status}
              >
                {this.state.status.map((item, idx) => {
                  return (
                    <MenuItem value={idx} key={idx}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 120 }}>
              <TextField
                select
                size="small"
                label="payment"
                name="filter_payment"
                id="select-payment"
                onChange={this.handleChangeFilter}
                value={this.state.filter_payment}
              >
                <MenuItem value={8}>Voucher</MenuItem>
                <MenuItem value={1}>QRIS</MenuItem>
              </TextField>
            </FormControl>
            <FormControl>
              <InputLabel id="select-perpage-label">Per Page</InputLabel>
              <Select
                size="small"
                label="Perpage"
                name="filter_perpage"
                id="select-perpage"
                labelId="select-perpage-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_perpage}
                sx={{ width: "100px" }}
              >
                {this.state.perpage.map((item, idx) => {
                  return (
                    <MenuItem value={idx} key={idx}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-perpage-label">Order By</InputLabel>
              <Select
                size="small"
                label="Order By"
                name="filter_order"
                id="select-order"
                labelId="select-order-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_order}
                sx={{ width: "100px" }}
              >
                {this.state.orderBy.map((item, idx) => {
                  return (
                    <MenuItem value={item.id} key={idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-perpage-label">Sort By</InputLabel>
              <Select
                size="small"
                label="Sort By"
                name="filter_sort"
                id="select-sort"
                labelId="select-sort-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_sort}
                sx={{ width: "100px" }}
              >
                {this.state.sortBy.map((item, idx) => {
                  return (
                    <MenuItem value={item.id} key={idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {!this.state.is_loading ? (
              <Button
                ref={(button) => (this.buttonElement = button)}
                variant="contained"
                onClick={this.handleFilterSubmit}
              >
                Filter
              </Button>
            ) : (
              <Typography className="btn-loading-filter">
                HARAP SABAR...
              </Typography>
            )}
            <Button variant="contained" onClick={this.handleFilterClear}>
              Clear Filter
            </Button>
          </Grid>
        </Grid>
        <Paper>
          <TableContainer sx={{ maxHeight: 700 }}>
            <InfiniteScroll
              height={500}
              dataLength={this.state.data.length}
              next={this.loadFunc}
              hasMore={this.state.hasMore}
              loader={
                <Typography
                  id="load-data"
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    padding: "15px",
                  }}
                >
                  Loading...
                </Typography>
              }
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell align="center">ID Order</TableCell>
                    <TableCell align="center">Total</TableCell>
                    <TableCell align="center">Frame</TableCell>
                    <TableCell align="center">Payment</TableCell>
                    <TableCell align="center">Description</TableCell>
                    <TableCell align="center" sx={{ width: "115px" }}>
                      Date
                    </TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tableBody}</TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </Paper>
        <Modal
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Stack direction="column" spacing={2}>
              <Typography variant="h5" sx={{ mb: 2, fontWeight: 800 }}>
                Sales Detail
              </Typography>
              <Grid container>
                <Grid item xs={5} md={5} lg={5}>
                  <Typography variant="p" sx={{ fontWeight: 800 }}>
                    ID Order
                  </Typography>
                </Grid>
                <Grid item xs={7} md={7} lg={7}>
                  <Typography variant="p">
                    {this.state.detail?.idorder || "ID Order"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5} md={5} lg={5}>
                  <Typography variant="p" sx={{ fontWeight: 800 }}>
                    Frame
                  </Typography>
                </Grid>
                <Grid item xs={7} md={7} lg={7}>
                  <Typography variant="p">
                    {this.state.detail?.frame?.name ?? "Tidak ada frame"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5} md={5} lg={5}>
                  <Typography variant="p" sx={{ fontWeight: 800 }}>
                    Payment
                  </Typography>
                </Grid>
                <Grid item xs={7} md={7} lg={7}>
                  <Typography variant="p">
                    {this.state.detail?.payment?.name ?? "Tidak ada payment"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5} md={5} lg={5}>
                  <Typography variant="p" sx={{ fontWeight: 800 }}>
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={7} md={7} lg={7}>
                  <Typography variant="p">
                    {this.state.detail?.status || "status"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5} md={5} lg={5}>
                  <Typography variant="p" sx={{ fontWeight: 800 }}>
                    Photo
                  </Typography>
                </Grid>
                <Grid item xs={7} md={7} lg={7}>
                  {this.state.detail !== null ? (
                    this.state.detail.photo !== null ? (
                      <Box
                        sx={{
                          background: "url(" + this.state.detail.photo + ")",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          backgroundRepeat: "no-repeat",
                          height: "190px",
                        }}
                      ></Box>
                    ) : (
                      <Typography variant="p">Tidak ada photo</Typography>
                    )
                  ) : (
                    <Typography variant="p">Tidak ada data</Typography>
                  )}
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: "35px !important" }}
                onClick={this.statusCek.bind(
                  this,
                  this.state.detail?.idorder || null
                )}
              >
                Cek Status
              </Button>
              {refund}
            </Stack>
          </Box>
        </Modal>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getSales: (data) => dispatch(getSales(data)),
    getBrandAll: (data) => dispatch(getBrandAll(data)),
    getBoothAll: (data) => dispatch(getBoothAll(data)),
    getBoothSearch: (data) => dispatch(getBoothSearch(data)),
    getSalesSearch: (data) => dispatch(getSalesSearch(data)),
    getSalesDetail: (data) => dispatch(getSalesDetail(data)),
    setRefund: (data) => dispatch(setRefund(data)),
    getFrameAll: (data) => dispatch(getFrameAll(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(SalesAll))
);
